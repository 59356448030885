import { lazy, Suspense, useContext, useEffect, useRef } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

import ViewLoader from "../components/Loader/ViewLoader/ViewLoader";
import { BreadCrumbContext } from "../context/BreadCrumbContext";
import { AppContext } from "../context/ContextWrapper";
import { getNodeApiUrl, submitRequest } from "../helpers/api";
import fetchData from "../helpers/api-cache";
import { sendViewEvent } from "../helpers/gtm";
import { isCorrectLanguage } from "../helpers/locales";
import { getTemplateFromNode } from "../helpers/node";
import useNodeData from "../hooks/use-node-data";
import locales from "../locales";
import NotFound from "./not-found";

/**
 *  This component decides which view needs to be rendered
 *  for the current route.
 */

export default function ViewSwitch() {
  const { setMobileVideo, setDesktopVideo } = useContext(AppContext);
  const { setBreadcrumbs } = useContext(BreadCrumbContext);

  const data = useLoaderData();
  const { node = null } = data;

  const { setNodeData } = useNodeData();

  const setNodeDataRef = useRef(null);
  setNodeDataRef.current = setNodeData;

  useEffect(() => {
    const mobileVideos = [
      "kutxa_anim_2_ok",
      "kutxa_anim_3_ok",
      "kutxa_anim_5_ok",
      "KF_01B_1072x4010_V4",
      "KF_01C_1072x4010_V5",
      "KF_02_1072x4010",
      "KF_03B_1072x4010",
      "KF_11_1072x4010_D_2_1",
      "KF_05_1072x4010",
      "KF_06_1072x4010",
      "KF_07_1072x4010",
      "KF_08_1072x4010",
      "KF_09_1072x4010_B",
      "KF_10_1072x4010_1",
    ];

    const desktopVideos = [
      "kutxa_anim_2_ok",
      "kutxa_anim_3_ok",
      "kutxa_anim_5_ok",
      "KF_01B_90x1920",
      "KF_01C_90x1920",
      "KF_02_90x1920",
      "KF_03B_90x1920",
      "KF_11_90x1920_D_1",
      "KF_05_90x1920",
      "KF_06_90x1920",
      "KF_07_90x1920",
      "KF_08_90x1920",
      "KF_09_90x1920_B",
      "KF_10_90x1920_1",
    ];

    const randomMobileVideo =
      mobileVideos[Math.trunc(Math.random() * mobileVideos.length)];
    const randomDesktopVideo =
      desktopVideos[Math.trunc(Math.random() * desktopVideos.length)];
    setMobileVideo(randomMobileVideo);
    setDesktopVideo(randomDesktopVideo);

    if (!node) {
      setNodeDataRef.current(null);
      return;
    }

    node
      .then((nodeData) => {
        setNodeDataRef.current(nodeData);
        setBreadcrumbs(nodeData.breadcrumbs ?? null);

        sendViewEvent(nodeData);
      })
      .catch((err) => {
        setNodeDataRef.current(null);
      });
  }, [node, setBreadcrumbs, setMobileVideo, setDesktopVideo]);

  if (!node) {
    return <NotFound />;
  }

  return (
    <Suspense fallback={<ViewLoader />}>
      <Await resolve={node} errorElement={<NotFound />}>
        {(node) => {
          if (node.error) {
            return <NotFound />;
          }

          const template = getTemplateFromNode(node);
          const ViewComponent = lazy(() => import(`./${template}`));

          return (
            <Suspense fallback={<ViewLoader />}>
              <ViewComponent node={node} />
            </Suspense>
          );
        }}
      </Await>
    </Suspense>
  );
}

export async function loader({ params }) {
  const { locale, "*": splat } = params;

  const nullNode = { node: null };

  if (!isCorrectLanguage(locales, locale)) {
    return nullNode;
  }

  const slug = splat ? `/${splat}` : "/home";

  const url = getNodeApiUrl(locale, slug);

  if (!url) {
    return nullNode;
  }

  const agendaUrl =
    "https://kutxafundazioa.eus/es/export/get-node/0?node_path=%2Fagenda";

  let nodePromise = {};

  if (url === agendaUrl || url.startsWith(agendaUrl)) {
    nodePromise = submitRequest(url);
  } else {
    nodePromise = fetchData(url);
  }

  return defer({
    node: nodePromise.then((response) => response.json()),
  });
}
